<template>
  <v-card height="370" max-width="280">
    <v-card-text class="pt-6 px-6 pb-6 fill-height">
      <v-row class="ma-0 fill-height" align-content="space-between" justify="center">
        <template v-if="service.showInfoIcon">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon color="primary"
                      class="abs-info-btn"
                      v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <div class="mw-200">
              {{ $t('tooltip.swPickup') }}
            </div>
          </v-tooltip>
        </template>
        <v-col cols="12" class="pa-0">
          <v-img :src="`${publicPath}${service.image}`" height="120" width="120" class="mx-auto my-6"></v-img>
          <h2 class="title text-center black--text mb-5">{{ $t(`${service.title}`) }}</h2>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-btn class="primary" @click="newOrderFormPage(service.path)">
            {{ $t('button.select') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceCardHomePage',
  props: {
    service: {
      type: Object,
      default() {
        return {
          image: {
            type: String,
            default: 'images/brand-logos/tmx-negyzet.png',
          },
          title: {
            type: String,
            default: 'title',
          },
          path: {
            type: Object,
            default() {
              return {
                path: '',
                url: '',
              };
            },
          },
          isExternalLink: {
            type: Boolean,
            default: false,
          },
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    newOrderFormPage(path) {
      if(this.service.isExternalLink) {
        window.open(path.url, '_blank');
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>

</style>
