import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"370","max-width":"280"}},[_c(VCardText,{staticClass:"pt-6 px-6 pb-6 fill-height"},[_c(VRow,{staticClass:"ma-0 fill-height",attrs:{"align-content":"space-between","justify":"center"}},[(_vm.service.showInfoIcon)?[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"abs-info-btn",attrs:{"color":"primary"}},on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2708246003)},[_c('div',{staticClass:"mw-200"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.swPickup'))+" ")])])]:_vm._e(),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VImg,{staticClass:"mx-auto my-6",attrs:{"src":("" + _vm.publicPath + (_vm.service.image)),"height":"120","width":"120"}}),_c('h2',{staticClass:"title text-center black--text mb-5"},[_vm._v(_vm._s(_vm.$t(("" + (_vm.service.title)))))])],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"primary",on:{"click":function($event){return _vm.newOrderFormPage(_vm.service.path)}}},[_vm._v(" "+_vm._s(_vm.$t('button.select'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }