<template>
  <v-container fluid class="primary lighten-2 pa-0">
    <v-img :src="require(`../../assets/img/${backgroundImage ? backgroundImage : 'slider-background.jpg'}`)" position="bottom right">
      <v-container class="py-10 fill-height">
        <v-row align="center" class="mx-0">
          <h1 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-regular' : 'display-2 mb-2'" style="width: 100%;">
            {{ $t(`${title}`) }}
          </h1>
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-regular' : 'display-1'">
            {{ $t(`${subtitle}`) }}
          </h3>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: 'HeroTitle',
  props: {
    backgroundImage: String,
    title: String,
    subtitle: String,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
