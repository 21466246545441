<template>
  <div v-frag>
    <hero-title-old title="welcomeText" subtitle="findOutMoreAboutOurServices"></hero-title-old>
    <v-container>
      <v-row justify="center">
        <template v-for="(item, index) in listThisItems">
          <!-- BEJELENTKEZETT USER START -->
          <template v-if="$store.state.authModule.isAuthenticated && $store.state.authModule.user">
            <v-col cols="auto"
                   v-if="item.roles.includes($store.state.authModule.user.profile.customerType)"
                   :key="index">
              <service-card-home-page :service="item"></service-card-home-page>
            </v-col>
          </template>
          <!-- BEJELENTKEZETT USER END -->
          <!-- NEM BEJELENTKEZETT USER START -->
          <template v-else>
            <v-col cols="auto"
                   v-if="!item.needLogin"
                   :key="index">
              <service-card-home-page :service="item"></service-card-home-page>
            </v-col>
          </template>
          <!-- NEM BEJELENTKEZETT USER END -->
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ServiceCardHomePage from 'src/components/services/ServiceCardHomePage.vue';
import HeroTitleOld from "../components/titles/HeroTitleOld";
// import ListServices from "../components/services/ListServices.vue";

export default {
  name: 'Home',
  components: {
    HeroTitleOld, ServiceCardHomePage,
  },
  mounted() {
    // console.log('TMXurl: ', this.TMXurl);
  },
  data() {
    return {
      listThisItems: this.$store.state.services.baseServices,
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
